<template>
  <section id="posts-list" class="mt-5 pb-10">
    <v-row :class="{ 'px-10': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xsOnly }">
      <v-col cols="12" sm="6" lg="4" v-for="post in posts" :key="post.id">
        <v-card class="mx-auto">
          <v-img height="250" :lazy-src="post.featured_image_url || require('@/assets/default-landscape.png')"
            :src="post.featured_image_url || require('@/assets/default-landscape.png')" :aspect-ratio="16/9" width="auto">
              <template v-slot:placeholder>
                <v-container fill-height class="d-flex align-center justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-container>
              </template>
            </v-img>
          <div class="pa-5">
            <h1 class="mb-3 text--primary">{{post.title}}</h1>
            <p class="gray--text">Posted on {{post.published_at}} by {{post.author}}</p>
            <h3 class="gray--text" :inner-html.prop="post.content | truncate(200)"></h3>
          </div>
          <v-card-actions class="px-5 pb-5">
            <v-spacer></v-spacer>
            <router-link :to="`news/${post.id}`">
              <v-btn color="primary" text>Read more<v-icon color="primary">mdi-chevron-right</v-icon></v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <div v-if="posts.length == 0" class="my-15 mx-auto">
        <h1>No posts to show yet!</h1>
      </div>
    </v-row>

    <div v-if="pagination.total_count != 0" class="text-center mt-10">
      <v-pagination
        v-model="pagination.current_page"
        :length="pagination.total_page"
        :total-visible="7"
        @input="fetchPosts(pagination.current_page)">
      </v-pagination>
    </div>
  </section>
</template>

<script>
  import apiService from '@/services/api.service'

  export default {
    name: 'PostsList',
    data() {
      return {
        posts: [],
        pagination: {
          current_page: 0,
          per: 0,
          total_count: 0,
          total_page: 0
        }
      }
    },
    filters: {
      truncate(content, size) {
        if (!content) return ''
        content = content.toString()

        if (content.length <= size) return content
        return content.substr(0, size) + '...'
      }
    },
    created() {
      this.fetchPosts()
    },
    methods: {
      async fetchPosts(page = null) {
        await apiService.get(`api/v1/posts/published?page=${page}`).then(res => {
          if (res.status == 200) {
            this.posts = res.data.data
            if (!page) this.pagination = res.data.pagination
          }
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="scss">
  #posts-list {
    h1 {
      font-size: 2em;
      line-height: 40px;
    }
  }
</style>
